/* src/components/Submissions.css */

.submission-container {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: #f3f4f6;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .submission-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .submission-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .submission-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 20px;
    margin-top: 10px;
    border-radius: 4px;
  }
  
  .submission-headline {
    font-size: 15px;
    margin-bottom: 10px;
  }
  
  .submission-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: black;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .submission-footer {
    font-size: 12px;
    color: #6b7280;
    margin-top: auto;
  }
  