/* src/App.css */
body {
  font-family: "Noto Sans", sans-serif;
  
}

form {
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 15px;
}

input[type="radio"] {
  margin-right: 10px;
}

button {
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

li div {
  font-weight: bold;
}

li div + div {
  margin-top: 10px;
}

